@import '~antd/dist/antd.css';

button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

a {
    text-decoration: none;
}